<template>
  <div>
    <v-form data-vv-scope="form-engine-location">
      <v-card class="mb-2">
        <v-card-title>
          <span class="headline" v-html="getTitle()"></span>
          <v-spacer></v-spacer>
          <div class="pr-2 d-flex align-content-end flex-column">
            <div class="align-self-end">
              <avatar
                @getCroppedImage="getCroppedImage"
                @deleteImage="icon = null"
                :image="activeEngineLocation.icon"
                data-testid="location-icon"
              >
              </avatar>
            </div>

            <div
              v-if="errors.has('form-engine-location.icon')"
              class="v-messages theme--dark error--text pt-2"
              data-testid="location-icon-errors"
            >
              Error on save image:
              {{ errors.first("form-engine-location.icon") }}
            </div>
          </div>
        </v-card-title>
        <v-card-text>
          <v-row class="pt-3">
            <v-col
              cols="12"
              md="12"
              v-if="errors.has('form-engine-location.general_error_field')"
            >
              <v-row>
                <v-col cols="12" md="6">
                  <v-alert
                    type="error"
                    text
                    data-testid="location-general-errors"
                  >
                    {{
                      errors.first("form-engine-location.general_error_field")
                    }}
                  </v-alert>
                </v-col>
              </v-row>
            </v-col>

            <v-col cols="12" sm="12">
              <v-row>
                <v-col cols="6" sm="6">
                  <v-row>
                    <v-col cols="12" sm="12">
                      <v-text-field
                        v-model="activeEngineLocation.name"
                        :error-messages="
                          errors.collect('form-engine-location.name')
                        "
                        label="Name"
                        placeholder=""
                        data-vv-name="name"
                        data-vv-validate-on="change"
                        data-vv-as="Name"
                        outlined
                        v-validate="'required'"
                        data-testid="name"
                      />
                    </v-col>

                    <v-col cols="12" sm="12" v-if="isSuperUser">
                      <SelectCompaniesAsync
                        v-model="activeEngineLocation.group_key"
                        outlined
                        label="Company"
                        :multiple="false"
                        item-value="_key"
                        item-text="name"
                        v-validate="'required'"
                        :error-messages="
                          errors.collect(`form-engine-location.group_key`)
                        "
                        data-vv-name="group_key"
                        data-vv-validate-on="change"
                        data-vv-as="company"
                        field="group_key"
                        :menu-props="{ maxHeight: 304 }"
                        :disabled="editedIndex !== -1"
                        data-testid="group"
                      />
                    </v-col>

                    <v-col cols="12" sm="12">
                      <SelectEnginesAsync
                        v-model="activeEngineLocation.engine"
                        outlined
                        label="Engine"
                        :multiple="false"
                        item-value="_key"
                        item-text="name"
                        v-validate="'required'"
                        :error-messages="
                          errors.collect(`form-engine-location.engine`)
                        "
                        data-vv-name="engine"
                        data-vv-validate-on="change"
                        field="engine"
                        :menu-props="{ maxHeight: 304 }"
                        keep="_key,name,serial_number"
                        :customFilters="customFilterEngines"
                        :disabled="
                          isSuperUser && !activeEngineLocation.group_key
                            ? true
                            : false
                        "
                        data-testid="engine"
                      />
                    </v-col>
                    <v-col cols="12" sm="12">
                      <v-select
                        label="Role"
                        outlined
                        v-model="activeEngineLocation.role"
                        :items="eventMembersRolesList"
                        item-value="_key"
                        item-text="name"
                        :menu-props="{ maxHeight: '400' }"
                        data-vv-name="role"
                        v-validate="'required'"
                        data-vv-validate-on="change"
                        :error-messages="
                          errors.collect(`form-engine-location.role`)
                        "
                        return-object
                        data-testid="role"
                      />
                    </v-col>
                    <v-col cols="12" sm="12">
                      <v-text-field
                        label="Street"
                        v-model="activeEngineLocation.address.street"
                        :error-messages="
                          errors.collect('form-engine-location.street')
                        "
                        data-vv-name="street"
                        data-vv-validate-on="change"
                        outlined
                        data-testid="street"
                      />
                    </v-col>
                  </v-row>
                </v-col>

                <v-col cols="6" sm="6">
                  <v-row>
                    <v-col md="12" sm="12" lg="12">
                      <v-autocomplete
                        v-model="city"
                        :items="cities"
                        label="City"
                        item-value="id"
                        item-text="city"
                        outlined
                        :error-messages="
                          errors.collect('form-engine-location.city')
                        "
                        data-vv-name="city"
                        data-vv-validate-on="change"
                        v-validate="'required'"
                        data-vv-as="City"
                        return-object
                        data-testid="city"
                      >
                        <template v-slot:item="{ item }">
                          <v-row class="justify-space-between">
                            <v-col>{{ item.city }}</v-col>
                            <v-col class="text-right text-caption">
                              {{ item.country }}
                            </v-col>
                          </v-row>
                        </template>
                      </v-autocomplete>
                    </v-col>
                    <v-col md="12" sm="12" lg="12">
                      <v-text-field
                        label="Country"
                        v-model="country"
                        :error-messages="
                          errors.collect('form-engine-location.country')
                        "
                        data-vv-name="country"
                        data-vv-validate-on="change"
                        v-validate="'required'"
                        outlined
                        disabled
                        data-testid="country"
                      />
                    </v-col>
                    <v-col md="12" sm="12" lg="12">
                      <v-autocomplete
                        v-model="timezone"
                        :items="timezones"
                        label="Time zone"
                        outlined
                        :error-messages="
                          errors.collect('form-engine-location.time_zone')
                        "
                        data-vv-name="time_zone"
                        data-vv-validate-on="change"
                        data-vv-as="Time zone"
                        v-validate="'required'"
                        :disabled="city ? true : false"
                        data-testid="timezone"
                      />
                    </v-col>
                    <v-col md="12" sm="12" lg="12">
                      <v-text-field
                        label="District"
                        v-model="activeEngineLocation.address.district"
                        :error-messages="
                          errors.collect('form-engine-location.district')
                        "
                        data-vv-name="district"
                        data-vv-validate-on="change"
                        outlined
                        data-testid="district"
                      />
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn
            color="error"
            text
            :to="'/store/locations'"
            data-testid="btn-close"
          >
            Close
          </v-btn>
          <v-btn
            color="success"
            text
            @click.stop="save()"
            data-testid="btn-save-location"
          >
            Save
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
  </div>
</template>

<script>
import { mapGetters } from "vuex"
import _ from "lodash"
import GeneralMixin from "@/mixins/general.js"
import momentTimezone from "moment-timezone"
import SelectEnginesAsync from "@/components/selects/SelectEnginesAsync"
import SelectCompaniesAsync from "@/components/selects/SelectCompaniesAsync"
import Avatar from "@/components/avatar/Avatar"
import * as cityTimezones from "city-timezones"
let cityList = cityTimezones.cityMapping.map((item, index) => {
  return {
    ...item,
    ...{ id: index },
  }
})

export default {
  props: {
    editedIndex: {
      type: [Number, String],
      default: -1,
    },
  },

  components: {
    SelectEnginesAsync,
    SelectCompaniesAsync,
    Avatar,
  },

  mixins: [GeneralMixin],

  data: () => ({
    timezones: momentTimezone.tz.names(),
    icon: null,
    cities: cityList,
  }),

  watch: {
    "activeEngineLocation.group_key": function () {
      if (this.editedIndex === -1) {
        this.activeEngineLocation.engine = null
      }
    },
  },

  created: function () {
    let self = this
    self.$store.dispatch("saveLoaderAction", true)
    this.$store.dispatch("getEventMembersRolesList", {
      params: { p: 1, pp: 100 },
    })
    self.$store.dispatch("resetEngineLocationItem").then(() => {
      self.getItemData().then(() => {
        self.icon = self.activeEngineLocation.icon
        self.$store.dispatch("saveLoaderAction", false)
        if (this.editedIndex === -1)
          self.$store.dispatch("setEngineLocationField", {
            field: "time_zone",
            value: momentTimezone.tz.guess(true),
          })
      })
    })
  },

  beforeDestroy() {
    this.$store.dispatch("resetEngineLocationItem")
  },

  methods: {
    getTitle() {
      return this.editedIndex === -1
        ? "Add new location"
        : `<span class="blue-grey--text text--lighten-4">Edit location</span> ${this.activeEngineLocation.name}`
    },
    getItemData() {
      return this.editedIndex !== -1
        ? this.$store.dispatch("getEngineLocationItem", {
            slug: this.editedIndex,
          })
        : Promise.resolve()
    },
    createEngineLocation(formData) {
      let self = this
      return new Promise((resolve, reject) => {
        self.$store
          .dispatch("createEngineLocation", formData)
          .then(({ data }) => {
            resolve(data)
          })
          .catch(({ response }) => {
            self.handleValidationErrors(response, "form-engine-location")
            reject(response)
          })
      })
    },
    editEngineLocation(formData) {
      let self = this
      return new Promise((resolve, reject) => {
        self.$store
          .dispatch("editEngineLocation", formData)
          .then(({ data }) => {
            resolve(data)
          })
          .catch(({ response }) => {
            self.handleValidationErrors(response, "form-engine-location")
            reject(response)
          })
      })
    },
    save() {
      let self = this
      self.$validator.errors.clear("form-engine-location")
      self.$validator.validateAll("form-engine-location").then((result) => {
        if (result) {
          let data = {
            data: {
              name: self.activeEngineLocation.name,
              engine_key: self.activeEngineLocation?.engine?._key,
              country: self.country,
              city: self.activeEngineLocation.city,
              time_zone: self.timezone,
              role_key: self.activeEngineLocation?.role?._key,
              flag: self.getMemberFlagIconByCountryName(),
              address: {
                district: self.activeEngineLocation?.address?.district,
                street: self.activeEngineLocation?.address?.street,
              },
            },
          }

          let prms
          if (this.editedIndex !== -1) {
            data["slug"] = self.editedIndex
            prms = self.editEngineLocation(data)
          } else {
            prms = self.createEngineLocation(data)
          }
          prms
            .then((data) => {
              let savedKey = _.get(data, "data._key")
              self
                .saveIcon(savedKey)
                .then(() => {
                  self.close().then(() => {
                    self.showSnackbar()
                  })
                })
                .catch((err) => {
                  console.log("saveIcon--error", err)
                  self.$validator.errors.add({
                    scope: "form-engine-location",
                    field: "icon",
                    msg: err.message,
                  })
                })
            })
            .catch(() => {
              // error on create or edit
            })
        }
      })
    },
    saveIcon(key) {
      let formData = new FormData()
      formData.append("icon", this.icon)
      return this.$store.dispatch("saveEngineLocationIcon", {
        slug: key,
        data: formData,
      })
    },
    close() {
      let self = this
      return new Promise((resolve, reject) => {
        self.$router
          .push({ path: "/store/locations" })
          .then(() => {
            resolve()
          })
          .catch((err) => {})
      })
    },
    showSnackbar() {
      let self = this
      let msg =
        self.editedIndex === -1
          ? `Location <strong>${this.activeEngineLocation.name}</strong> was added successfully.`
          : `Location <strong>${this.activeEngineLocation.name}</strong> was edited successfully.`
      let params = {
        text: msg,
        show: true,
      }
      self.$root.$emit("snackbar", params)
    },
    getCroppedImage(file) {
      this.icon = file
    },
    // @todo return this after will be resolved problem with store images on backend servers cluster (now is stored in db in base 64 format)
    getFullIconLink() {
      if (this.activeEngineLocation.icon) {
        let domain =
          window.location.hostname === "localhost"
            ? "https://dev.arht.redox-dev.com/"
            : window.location.hostname
        return `${domain}${this.activeEngineLocation.icon}`
      }
      return this.activeEngineLocation.icon
    },
    getMemberFlagIconByCountryName() {
      return this.city?.iso2
        ? `https://flagcdn.com/${this.city?.iso2.toLowerCase()}.svg`
        : null
    },
  },

  computed: {
    ...mapGetters({
      showTableLoader: "getShowTableLoader",
      Pallette: "get_Pallette",
      activeEngineLocation: "activeEngineLocation",
      eventMembersRolesList: "eventMembersRolesList",
      isSuperUser: "isSuperUser",
    }),

    city: {
      get() {
        if (
          this.activeEngineLocation?.city &&
          this.activeEngineLocation?.country
        ) {
          const cityLookup = this.cities.find(
            (item) =>
              item.city === this.activeEngineLocation?.city &&
              item.country === this.activeEngineLocation?.country
          )
          // const cityLookup = cityTimezones.lookupViaCity(
          //   this.activeEngineLocation?.city
          // )
          return cityLookup
        }
        return null
      },
      set(val) {
        this.$store.dispatch("setEngineLocationField", {
          field: "city",
          value: val.city,
        })
        this.$store.dispatch("setEngineLocationField", {
          field: "country",
          value: val.country,
        })
      },
    },

    country() {
      return this.city ? this.city?.country : this.activeEngineLocation?.country
    },

    timezone() {
      return this.city
        ? this.city?.timezone
        : this.activeEngineLocation.time_zone
    },

    customFilterEngines() {
      return this.activeEngineLocation?.group_key
        ? { company_key: this.activeEngineLocation?.group_key }
        : {}
    },
  },
}
</script>
